import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Grazie = () => (
  <Layout>
    <SEO title="Grazie per averci contattato" />
		<main>
    <div>
			<h1>Grazie per averci inviato il messaggio</h1>
			    <p>Abbiamo ricevuto il messaggio e ti risponderemo al più presto possible.</p>
		</div>
		</main>
	</Layout>
)

export default Grazie
